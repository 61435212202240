.footer {
    background-color: #686868;
    color: #FFFFFF;
    padding-bottom: 80px;
}
.footer .navbar-brand {
    color: #FFFFFF;
}
.footer-nav-link a {
    color: #FFFFFF !important;
}
.footer-navbar {
    padding-bottom: 45px;
    padding-top: 90px;
}
.footer-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, .2);
    padding-top: 52px;
}
.p-follow {
    margin: 0;

}

.italic-text-logo {
    width: 215px;
    height: 37px;
}

@media (min-width: 1200px){
    .footer .footer-container {
        max-width: 1580px;
    }
    .footer .footer-bottom-container {
        max-width: 1580px;
    }
}

.footer-bottom-ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 16px;
}
.footer-bottom-ul .nav-link {
    padding: 0 8px;
    list-style-type: none;
}

.footer-bottom-right {
    /* display: flex; */
    display: none;
    flex-basis: auto;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}
.btn-register {
    background: #000000;
    border-radius: 15px;
    color: #FFFFFF;
}
.social-icon {
    width: 48px;
    height: 48px;
}
.footer .navbar-light button.navbar-toggler {
    background-color: #F68817;
    color: #ffffff;
}
.footer-logo-title {
    font-size: 32px;
    font-family: SFPro-BoldItalic, SFPro;
    font-weight: normal;
    color: #FFFFFF;
}
@media (max-width: 680px) {
    .footer-navbar, .footer-bottom {
        padding: 20px 0;
    }
    /*footer {*/
    /*    padding-bottom: 40px !important;*/
    /*}*/
    .footer-bottom-container {
        margin-bottom: 30px;
    }
}