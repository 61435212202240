.privacy-wrapper .real-content {
    line-height: 1.7;
    margin-top: 15px;
}
.privacy-wrapper .real-content p {
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #000000;
    line-height: 28px;
    margin-bottom: 20px;
}
.privacy-wrapper h1 {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 50px;
    margin-bottom: 26px;
}
