.carrier-order-list-wrapper {
  background: #f1f1f1;
  overflow: hidden;
}

.carrier-order-list-container {
  max-width: 840px;
  padding: 32px;
}

.tabbar {
  width: 100%;
  border-bottom: 1px solid #686868;
  display: flex;
  flex-direction: row;
}

.tabbar .divider {
  margin: 12.5px 23px;
  width: 1px;
  align-self: stretch;
  background: #cecac4;
}

.tabbar .tab-item {
  line-height: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  color: #000000;
}

.tabbar .tab-item:hover {
  cursor: pointer;
}

.tabbar .tab-item-active {
  color: #ea7e30;
}

.list-wrapper {
  width: 100%;
  padding: 24px 0;
}

.list-wrapper .list-item-rename {
  padding: 12px 0 !important;
}

.order-item-container {
  border-radius: 8px;
  background: #ffffff;
  padding: 36px 48px;
  width: 100%;
}

.order-item-container .order-id-text {
  font-size: 16px;
  color: #000000;
  line-height: 22px;
}

.order-item-container .status-text {
  font-size: 16px;
  font-weight: 600;
  color: #ea7e30;
  line-height: 22px;
  float: right;
}

.order-item-container .middle-container {
  border-radius: 8px;
  background: #f1f1f1;
  padding: 20px 24px;
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.order-item-container .middle-container .left-group {
  flex-grow: 1;
}

.order-item-container .middle-container .divider {
  margin: 4px 16px;
  width: 1px;
  align-self: stretch;
  background: #cecac4;
}

.order-item-container .middle-container .right-group {
  flex-grow: 2;
}

.order-item-container .middle-container .cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding: 4px 0;
}

.order-item-container .middle-container .cell .cell-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  flex-shrink: 0;
}

.order-item-container .middle-container .cell .cell-title {
  font-weight: 600;
  flex-shrink: 0;
}

.order-item-container .middle-container .cell .cell-content {
  font-weight: 400;
  flex-grow: 1;
  text-align: right;
}

.order-item-container .estimated-title {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.order-item-container .estimated-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.order-item-container .estimated-container .left-group {
  flex-grow: 9;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-item-container .estimated-container .divider {
  margin: 0 42px;
  width: 1px;
  align-self: stretch;
  background: #cecac4;
}

.order-item-container .estimated-container .right-group {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-item-container .estimated-container .date-title {
  margin-right: 16px;
  flex-shrink: 0;
}

.order-item-container .estimated-container .date-picker-trigger {
  padding: 5px 12px;
  min-width: 120px;
  border-radius: 2px;
}

.order-item-container .estimated-container .time-picker-trigger {
  padding: 5px 12px;
  border-radius: 2px;
}

.order-item-container .estimated-container .trigger-not-set {
  border: 1px solid #f53f3f;
}

.order-item-container .estimated-container .time-divider {
  margin: 0 16px;
}

.order-item-container .estimated-container .end-time {
  padding: 6px 12px;
  border-radius: 2px;
  flex-grow: 1;
  background: #f1f1f1;
  color: #686868;
}

.order-item-container .links-container {
  margin-top: 36px;
  display: flex;
}

.order-item-container .links-container .text-link {
  font-size: 16px;
  line-height: 22px;
  color: #ea7e30;
  text-decoration-line: underline;
}

.order-item-container .actions-container {
  margin-top: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.order-item-container .actions-container .delivery-date {
  margin-right: auto;
  color: #000000;
  font-size: 16px;
}

.order-item-container .actions-container .button {
  margin-left: 32px;
  padding: 0 36px;
  font-size: 14px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #ea7e30;
  text-decoration: none;
}

.order-item-container .actions-container .button:hover {
  cursor: pointer;
}

.order-item-container .actions-container .primary {
  color: #ffffff;
  background: #ea7e30;
}

.order-item-container .actions-container .outline {
  color: #ea7e30;
}
