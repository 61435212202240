body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: PingFangSC-Light, PingFang SC;
}

html,
body {
  height: 100%;
}
/*html {height:100%}*/
/*body {min-height:100%}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.transaction-wrapper, .privacy-wrapper {
  overflow: hidden;
}
.content-wrapper .container {
  background: #f7f7f7;
  padding: 40px;
  min-height: calc(100vh - 300px);
}