.body-wrapper-0 {
  background-image: url("../../assets/first_bg.png");
  background-size: 100% 100%;
  /*overflow: hidden;*/
}
.body-wrapper-0 .container {
  background: url("../../assets/map_top.png") no-repeat right top;
  background-size: 730px;
}

.bg-extra-space {
  background-image: url("../../assets/pic_truck@2x.png");
  height: 406px;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 598px;
}
.slogan-wrapper {
  position: relative;
  top: 79px;
  /*margin-top: 79px;*/
}
.word-line {
  width: 275px;
}
.slogan-wrapper,
.slogan-wrapper h2 {
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  font-size: 40px;
  line-height: 56px;
  color: #000000;
}
.slogan-wrapper .btn-wrapper {
  margin-top: 49px;
}

.for-shipper-title {
  margin-bottom: 30px;
}
.slogan-wrapper button {
  height: 60px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  background: linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%);
  border-radius: 0px 8px 8px 0px;
  border: none;
}

.slogan-wrapper .btn-download {
  background: linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%);
  border-radius: 8px 0px 0px 8px;
}

.slogan-wrapper .btn-post-router {
  font-weight: 600;
  color: #f68817;
  background: #ffffff !important;
  border: 2px solid #f68817;
}

@media (max-width: 780px) {
  .bg-extra-space {
    background-image: none;
    height: auto;
  }
  .slogan-wrapper .btn-wrapper {
    text-align: center;
  }
}
.box-item {
  background: url("../../assets/item_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: 36px;
  max-width: 260px;
  box-shadow: 0px 20px 24px -12px rgba(246, 136, 23, 0.24);
  border: 2px solid #f68817;
  margin: 20px auto;
}

.box-item span.block-title-num {
  font-size: 24px;
  font-family: AppleSystemUIFont;
  color: #f68817;
}
.box-item h2 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 12px;
}

.body-wrapper-1 {
  margin-top: 95px;
  margin-bottom: 80px;
}

.body-wrapper-1 center .description,
.body-wrapper-2 center .description {
  max-width: 580px;
  text-align: left;
}
.platform-wrapper {
  background-color: #f68817;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 44px 0;
}
.platform-list {
  background-color: transparent !important;
  display: flex;
  justify-content: space-between;
}
.platform-list .list-group-item {
  background-color: transparent !important;
  border: none;
}
.platform-title {
  font-size: 31px;
  font-weight: 500;
  color: #ffffff;
}

.platform-sub-title {
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 0;
}
.sub-title {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f68817;
  line-height: 50px;
  margin-bottom: 26px;
  margin-left: 20px;
}

.body-wrapper-2 {
  background: linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%);
  padding-top: 100px;
  padding-bottom: 80px;
}

.body-wrapper-2 .container center {
  color: #ffffff;
}
.body-wrapper-2 .container center h1 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  margin-bottom: 30px;
  color: #ffffff;
}
.body-wrapper-2 .container center .description {
  font-size: 18px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #ffffff;
  line-height: 28px;
  max-width: 900px;
}
.p-register-description {
  font-size: 18px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #000000;
  line-height: 28px;
  margin-bottom: 38px;
}

.left-box-top {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
}

.nva-tabs-custom-box {
  flex-wrap: wrap;
  display: grid;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f68817 !important;
  justify-items: center;
  align-items: center;
  align-content: center;
  background-color: #ffffff !important;
}

.icon-right-arrow {
  margin-left: 6px;
}

.left-box-bottom {
  /* margin-left: 58px; */
}

.body-wrapper-1 .btn-about-user {
  background: #f3e1ce;
  border-radius: 10px;
  border-color: #f3e1ce;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f68817;
  margin-left: 20px;
}

/* .text_19 {
    width: 38px;
    height: 50px;
    overflow-wrap: break-word;
    color: rgba(246, 136, 23, 1);
    font-size: 40px;
    font-family: SFPro-Regular;
    text-align: left;
    white-space: nowrap;
    line-height: 50px;
} */

.italic-text-logo {
  width: 215px;
  height: 37px;
}

.body-wrapper-1 .body-wrapper-row,
.body-wrapper-2 .body-wrapper-row {
  margin-top: 40px;
}

.body-wrapper-1 .left-box h1 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #f68817;
  line-height: 50px;
  margin-bottom: 26px;
}

.left-box .description {
  width: 436px;
  font-size: 18px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #000000;
  line-height: 28px;
}

.body-wrapper-accordion .accordion-item,
.body-wrapper-2-accordion .accordion-item {
  border: 3px solid rgba(230, 223, 217);
  margin-bottom: 20px;
}
.body-wrapper-accordion .accordion-item .accordion-button {
  border-radius: unset;
}

.body-wrapper-accordion button {
  font-size: 24px;
  background: #f3f1ef;
  border: none;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}

.body-wrapper-accordion .accordion-button::after {
  background-image: url("../../assets/ic_increase@2x.png");
  background-repeat: no-repeat;
}

.body-wrapper-accordion .accordion-button.collapsed::after {
  background-image: url("../../assets/ic_add_green@2x.png");
  background-repeat: no-repeat;
}

.body-wrapper-accordion button {
  height: 112px;
  overflow: hidden;
}

.body-wrapper-accordion button span {
  font-size: 24px;
  font-family: Gotham-Medium, Gotham;
  font-weight: 500;
  color: #f68817;
  margin-right: 30px;
}

.body-wrapper-accordion .accordion-button:not(.collapsed) {
  background-color: #ffffff;
  border-left: 3px solid #f68817;
  box-shadow: none;
}

.body-wrapper-accordion .accordion-collapse.collapse.show {
  border-left: 3px solid #f68817;
}

.body-wrapper-accordion {
  --bs-accordion-bg: none !important;
}

.body-wrapper-accordion .accordion-body {
  background: #ffffff;
  padding-bottom: 36px;
  padding-top: 29px;
}

.nva-tabs-custom {
  background: #ffffff;
  border-radius: 6px;
  border: 2px solid #f68817;
  max-width: 400px;
  padding: 6px;
}

.nva-tabs-custom .nav-item a h1 {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
}

.nva-tabs-custom .nav-item a.active h1 {
  color: #ffffff;
}

.nva-tabs-custom .nav-item .nva-tabs-custom-box {
  padding: 12px 0;
}

.nva-tabs-custom .nav-item a.active .nva-tabs-custom-box {
  background: linear-gradient(135deg, #fa7c06 0%, #ffad0c 100%);
  border-radius: 4px;
}

.nva-tabs-custom .nav-item a {
  height: 100%;
  background-color: #ffffff !important;
  padding: 0;
}

.icon-user {
  width: 41px;
}

.icon-driver {
  width: 32px;
}

.nva-tabs-custom .nav-item img,
.nva-tabs-custom .nav-item h1 {
  display: block;
}

.text_31 {
  width: 38px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 40px;
  font-family: SFPro-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
}

.btn-about-carrier {
  background: #e2a934;
  border-radius: 10px;
  border-color: #e2a934;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin-left: 20px;
}

.body-wrapper-3 {
  margin-top: 200px;
  margin-bottom: 120px;
  height: 500px;
  position: relative;
}
.body-wrapper-3 .position-absolute {
  position: absolute;
  /*left: 0;*/
  right: 0;
  margin-left: auto;
  top: -30px;
}

.body-wrapper-3 .wrapper-3-container {
  position: relative;
  background: url("../../assets/arrow_big.png") no-repeat left center;
  background-size: 100% 100%;
  height: -webkit-fill-available;
}

.body-wrapper-3 .wrapper-3-container .wrapper-3-content {
  position: absolute;
  right: 0;
  margin-left: auto;
  top: -30px;
  width: 100%;
}

.body-wrapper-3 .left-box-top {
  margin-top: 52px;
}
.body-wrapper-3 .tab-content {
  max-width: 600px;
}
.body-wrapper-4 .left-box-top h1 {
  margin-left: 20px;
}

.text_54 {
  width: 38px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(246, 136, 23, 1);
  font-size: 40px;
  font-family: SFPro-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
  margin-top: 5px;
}

body-wrapper-3 {
  background: #ffffff;
}

.body-wrapper-4 {
  background: #e1e1e1;
  padding-bottom: 0;
  overflow: hidden;
}

.body-wrapper-4 .left-box-top {
  margin-top: 115px;
}

.body-wrapper-4 .content-wrapper h1 {
  font-size: 40px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #f68817;
  line-height: 60px;
}

.body-wrapper-4 .btn-register {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  width: 200px;
  height: 60px;
  border-color: transparent;
}
.body-wrapper-4 {
  background: linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%);
}

.body-wrapper-4 .bg-div {
  background: url("../../assets/map_bottom.png") no-repeat left center;
  background-size: auto 100%;
  height: 480px;
  margin: 30px 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.bg-div .container h1 {
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  margin-top: 50px;
  margin-bottom: 27px;
}
p.wish-description {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 36px;
  margin-top: 70px;
  max-width: 860px;
}
.white-line {
  width: 100px;
  height: 3px;
  background: #ffffff;
}

.img-phone {
  width: 278px;
  position: absolute;
  top: -100px;
  left: 250px;
}

.text_45 img {
  width: 23px !important;
}

.text_45 {
  width: 23px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(246, 136, 23, 1);
  font-size: 20px;
  font-family: SFPro-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 32px;
  margin-right: 12px;
}

.join-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  padding-left: 0;
  margin-bottom: 38px;
  margin-top: 22px;
}

.join-description {
  font-size: 18px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #000000;
  line-height: 28px;
}

.join-ul li {
  overflow-wrap: break-word;
  list-style: none;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-top: 12px;
  width: 240px;
}

.btn-qr {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  border-color: transparent;
  height: 60px;
  background: linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%);
  border-radius: 0px 8px 8px 0px;
  border: none;
  padding: 0;
}

.btn-download {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  border-color: transparent;
  height: 60px;
  background: linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%);
  border-radius: 8px 0px 0px 8px;
  border: none;
  padding: 0;
}

.download-toast {
  position: absolute;
}

.qr-img {
  width: 24px;
}

.video_player {
  height: 315px;
  width: 100%;
  margin-top: 40px;
  position: relative;
  /* width:100%;height:0;padding-bottom:56.25%; */
}
.video_player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 960px) {
  .body-wrapper-4 .bg-div {
    background: transparent;
  }
}

@media (max-width: 780px) {
  .box-item {
    max-width: none;
    margin: 20px 0;
  }
  .body-wrapper-1,
  .body-wrapper-2 {
    padding: 40px 10px;
  }
  .body-wrapper-2 .container center .description {
    margin-top: 20px;
  }
  .body-wrapper-1 .body-wrapper-row,
  .body-wrapper-2 .body-wrapper-row {
    margin: 0;
    padding: 0;
  }
  .left-box,
  .left-box-bottom {
    margin: 20px;
  }
  .left-box .description {
    width: auto;
  }
  .left-box-top {
    margin-top: 20px;
  }
  .body-wrapper-3 {
    height: auto;
    background: none;
    margin-bottom: 0;
  }
  .body-wrapper-3 .position-absolute {
    position: relative !important;
  }
  .img-phone {
    position: relative !important;
  }
  .body-wrapper-4 .left-box-top {
    margin: 20px;
  }
  .body-wrapper-4 .left-box-bottom {
    margin-bottom: 20px;
  }
  .body-wrapper-4 .bg-div {
    background: none;
  }

  .footer-bottom-right {
    margin-top: 20px;
  }

  .video-box {
    width: 100%;
  }
}

.icon-wish {
  width: 40px;
}
