.post-router-wrapper {
    background-color: #FBF2DF;
    overflow: hidden;
}
.page-header-title{
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin-bottom: 60px;
}
.post-router-wrapper .container {
    margin-top: 60px;
    margin-bottom: 100px;
}
.post-router-container {
    max-width: 720px;
}
.post-router-wrapper .box {
    margin-bottom: 43px;
}
.box-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.post-router-form label.col-form-label {
    text-align: right;
}

.col-form-label[textalign="center"] {
    text-align: center !important;
}

.box-header .img-arrow {
    width: 23px;
    margin-right: 12px;
    display: block;
    float: left;
}
.label-arrow-icon {
    width: 23px;
    margin-right: 6px;
}

form .form-group-box {
    /*padding: 0 40px;*/
}

.send-code-col {
    text-align: right;
}
.box-header h3, .box-header .h3 {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin: 0;
}
.post-router-wrapper form {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px;
    max-width: 720px;
}
.btn-submit {
    width: 220px;
    background: linear-gradient(135deg, #FA7C06 0%, #FFAD0C 100%);
    border-color: transparent;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    padding: 14px;
}

form div {
    position: relative;
}

form label[aria-required="true"]::before {
    content: "*";
    color: #F61717;
    margin-right: 6px;
}

.btn-send-code, .btn-send-code:hover, .btn-send-code.disabled {
    /*width: 106px;*/
    text-align: center;
    color: #F68817 !important;
    background: #FFFFFF !important;
    border: 2px solid #F68817 !important;
}
form .row {
    margin-top: 0;
    margin-right: 0;
}
@media (max-width: 780px) {
    .post-router-form label.col-form-label {
        text-align: left;
    }
    .post-router-wrapper .box {
        margin-bottom: 0px;
    }
    .form-group-box div {
        margin-bottom: 10px;
    }
}

.form-group-box .col-sm-2{
    width: 22.2%!important;
}