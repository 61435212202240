.quote-wrapper-0 {
  background-image: url("../../assets/quote_top_bg.png");
  background-size: 100% 100%;
  min-height: 400px;
}
.slogan-wrapper {
  position: relative;
  top: 79px;
}
.word-line {
  width: 275px;
}
.slogan-wrapper,
.slogan-wrapper h2 {
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  font-size: 40px;
  line-height: 56px;
  color: #000000;
}

.italic-text-logo {
  width: 215px;
  height: 37px;
}

.quote-wrapper-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.quote-wrapper-row .quote-item-box {
  padding: 56px 0;
  max-width: 20%;
  flex-grow: 0;
  flex-shrink: 0;
}

.quote-wrapper-row .quote-item-box .number {
  color: #f68817;
  font-family: "PT Serif Caption";
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 12px;
  margin-bottom: 0;
}

.quote-wrapper-row .quote-item-box .title {
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  line-height: 21.5px;
  padding-bottom: 12px;
  margin-bottom: 0;
}

.quote-wrapper-row .quote-item-box .content {
  color: #686868;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.5px;
  margin-bottom: 0;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.quote-wrapper-3 {
  background-color: #fff7e8;
}

.quote-wrapper-3 .form-container {
  padding: 40px 0;
}

.quote-wrapper-3 .form-container .form-group {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 40px;
}

.quote-wrapper-3 .form-container .mt-40 {
  margin-top: 40px;
}

.quote-wrapper-3 .form-container .mt-56 {
  margin-top: 56px;
}

.quote-wrapper-3 .form-container .form-group .top-alert {
  padding: 9px 16px;
  border-radius: 8px;
  border: 1px solid #ea7e30;
  margin-bottom: 32px;
  background-color: #fff7e8;
}

.quote-wrapper-3 .form-container .form-group .top-alert .top-alert-icon {
  line-height: 21.5px;
  margin-right: 8px;
  width: 15px;
}

.quote-wrapper-3 .form-container .form-group .top-alert span {
  color: #1d2129;
  font-size: 14px;
  line-height: 21.5px;
}

.quote-wrapper-3 .form-container .form-group .form-group-title {
  margin-bottom: 24px;
  color: #ea7e30;
  font-size: 22px;
  line-height: 32px;
}

.quote-wrapper-3 .form-container .form-group .delivery-method-item {
  height: 80px;
  padding: 8px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quote-wrapper-3 .form-container .form-group .delivery-method-item-active {
  border: 1px solid #ea7e30;
}

.quote-wrapper-3 .form-container .form-group .delivery-method-item-normal {
  border: 1px solid #cecac4;
  background: #f1f1f1;
}

.quote-wrapper-3
  .form-container
  .form-group
  .delivery-method-item
  .delivery-method-item-icon {
  width: 34px;
  height: 24px;
}

.quote-wrapper-3
  .form-container
  .form-group
  .delivery-method-item
  .delivery-method-item-title {
  font-size: 14px;
  line-height: 21.5px;
  margin-bottom: 0;
  margin-top: 4px;
}

.quote-wrapper-3
  .form-container
  .form-group
  .delivery-method-item
  .delivery-method-item-des {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
}

.quote-wrapper-3
  .form-container
  .form-group
  .delivery-method-item-active
  .delivery-method-item-title {
  color: #ea7e30;
}

.quote-wrapper-3
  .form-container
  .form-group
  .delivery-method-item-active
  .delivery-method-item-des {
  color: #999;
}

.quote-wrapper-3 .form-container .form-group .delivery-method-item-normal p {
  color: rgba(0, 0, 0, 0.6);
}

.quantity-des {
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  color: #777;
}

.quote-wrapper-3 .form-container .quote-buttons-box {
  display: flex;
  justify-content: flex-end;
}

.quote-wrapper-3 .form-container .quote-buttons-box .quote-button {
  border-radius: 8px;
}

.quote-wrapper-3 .form-container .quote-buttons-box .quote-button-disable {
  background: #cecac4;
  color: #000;
}

.quote-wrapper-3 .form-container .quote-buttons-box .quote-button-enable {
  background: linear-gradient(166deg, #ea7e30 0%, #f5be51 100%);
  color: #fff;
}

.quote-wrapper-3 .form-container .quote-buttons-box .quote-button span {
  font-size: 17px;
  font-weight: 600;
  line-height: 60px;
  padding: 0 80px;
}

.quote-wrapper-3 .form-container .form-group .price-top-title {
  color: #000;
  font-size: 17px;
  line-height: 21.5px;
}

.quote-wrapper-3 .form-container .form-group .price-bottom-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.quote-wrapper-3 .form-container .form-group .price-bottom-box .left-box {
  flex-grow: 1;
  display: flex;
}

.quote-wrapper-3
  .form-container
  .form-group
  .price-bottom-box
  .left-box
  .address-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote-wrapper-3
  .form-container
  .form-group
  .price-bottom-box
  .left-box
  .address-item
  .top {
  display: flex;
  align-items: center;
}

.quote-wrapper-3
  .form-container
  .form-group
  .price-bottom-box
  .left-box
  .address-item
  .top
  .icon {
  width: 16px;
  height: 16px;
}

.quote-wrapper-3
  .form-container
  .form-group
  .price-bottom-box
  .left-box
  .address-item
  .top
  .title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.5px;
  margin-left: 4px;
}

.quote-wrapper-3
  .form-container
  .form-group
  .price-bottom-box
  .left-box
  .address-item
  .content {
  color: #000;
  font-size: 16px;
  line-height: 21.5px;
}

.quote-wrapper-3
  .form-container
  .form-group
  .price-bottom-box
  .left-box
  .gradient-line {
  margin: 10px 4px 0 4px;
  width: 64px;
  height: 1px;
  background: linear-gradient(270deg, #ee8c00 15%, #000 100%);
}

.quote-wrapper-3 .form-container .form-group .price-bottom-box .price-title {
  flex-grow: 0;
  color: #000;
  font-size: 16px;
  line-height: 21.5px;
}

.quote-wrapper-3 .form-container .form-group .price-bottom-box .price {
  flex-grow: 0;
  margin-left: 10px;
  color: #ea7e30;
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
}

.quote-wrapper-3
  .form-container
  .form-group
  .price-bottom-box
  .next-step-button {
  flex-grow: 0;
  border-radius: 8px;
  background: linear-gradient(166deg, #ea7e30 0%, #f5be51 100%);
  margin-left: 40px;
}

.quote-wrapper-3
  .form-container
  .form-group
  .price-bottom-box
  .next-step-button
  span {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  line-height: 60px;
  padding: 0 70px;
}

.login-form-container {
  display: flex;
  justify-content: center;
  padding: 80px 210px;
}

.quote-wrapper-3 .form-container .form-group .form-group-subtitle {
  padding: 8px 0 16px 0;
}

.quote-wrapper-3
  .form-container
  .form-group
  .form-group-subtitle
  .form-group-subtitle-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-bottom: 3.5px;
  margin-left: -1px;
}

.quote-wrapper-3
  .form-container
  .form-group
  .form-group-subtitle
  .form-group-subtitle-text {
  display: inline-block;
  margin-left: 7px;
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
}

.quote-wrapper-3 .bottom-buttons {
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.quote-wrapper-3 .bottom-buttons .bottom-button {
  margin-left: 24px;
  border-radius: 8px;
  width: 194px;
  text-align: center;
}

.quote-wrapper-3 .bottom-buttons .bottom-button span {
  font-size: 17px;
  font-weight: 600;
  line-height: 60px;
}

.quote-wrapper-3 .bottom-buttons .bottom-button div {
  font-size: 17px;
  font-weight: 600;
  line-height: 60px;
}

.quote-wrapper-3 .bottom-buttons .pre-step {
  border: 2px solid #ea7e30;
  background: #fff;
}

.quote-wrapper-3 .bottom-buttons .pre-step span {
  color: #ea7e30;
}

.quote-wrapper-3 .bottom-buttons .next-step-enable {
  background: linear-gradient(166deg, #ea7e30 0%, #f5be51 100%);
}

.quote-wrapper-3 .bottom-buttons .next-step-enable span {
  color: #fff;
}

.quote-wrapper-3 .bottom-buttons .next-step-disable {
  background: #cecac4;
}

.quote-wrapper-3 .bottom-buttons .next-step-disable span {
  color: #000;
}

.quote-wrapper-3 .preview-group {
  padding: 40px;
  border-radius: 24px;
  background: #fff;
  margin-bottom: 16px;
}

.quote-wrapper-3 .preview-group-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.quote-wrapper-3 .preview-group .group-title {
  margin-bottom: 16px;
  color: #ea7e30;
  font-size: 22px;
  line-height: 32px;
}

.quote-wrapper-3 .preview-group .price {
  font-size: 26px;
  color: #ea7e30;
  font-weight: 600;
  line-height: 40px;
}

.quote-wrapper-3 .preview-group .content {
  font-size: 14px;
  line-height: 21.5px;
  color: #000;
}

.quote-wrapper-3 .preview-group .pb-185 {
  padding-bottom: 18.5px;
}

.quote-wrapper-3 .preview-group-flex .divider {
  margin: 0 24px;
  width: 1px;
  background: #cecac4;
  align-self: stretch;
}

.quote-wrapper-3 .preview-group-flex .item {
  flex-grow: 1;
}
