.login-wrapper {
  background: #ffffff;
  overflow: hidden;
}

.login-container {
  display: flex;
  flex-direction: row;
}

.login-container .left-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container .left-wrapper .top-space {
  height: 20vh;
  width: 100%;
}

.login-container .left-wrapper .top-space .header-line {
  padding: 24px;
  display: flex;
  flex-direction: row;
}

.login-container .left-wrapper .top-space .header-line .item {
  margin-left: 24px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
}

.login-container .left-wrapper .top-space .header-line .item:hover {
  cursor: pointer;
}

.login-container .left-wrapper .logo-img {
  padding-top: -100px;
  display: block;
  width: 78px;
  height: 78px;
  border-radius: 4px;
}

.login-container .left-wrapper .logo-context {
  margin: 48px 0;
  display: block;
  white-space: pre-line;
  -webkit-line-clamp: 2;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}

.login-container .left-wrapper .login-form {
  width: 220px;
}

.login-container .left-wrapper .login-form-label {
  font-size: 16px;
  color: #1D2129;
}

.login-container .left-wrapper .login-form-filled {
  border-radius: 2px;
}

.login-container .left-wrapper .login-form-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.login-container .left-wrapper .login-form-button {
  margin-top: 24px;
  width: 140px;
  font-weight: 600;
}

.login-container .right-wrapper {
  width: 50%;
  height: 100vh;
}
