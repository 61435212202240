.Header-wrapper {
  background-color: #ffffff;
}
.App-header .header-navbar {
  background: linear-gradient(165.96deg, #ea7e30 0%, #f5be51 100%);
  opacity: 0.9;
}
.logo-title .logo-title-img {
  /* font-size: 24px;
    font-family: SFPro-BoldItalic, SFPro;
    font-weight: 600;
    color: #000000; */
  width: 168px;
}
@media (min-width: 1200px) {
  .Header-wrapper .container-navbar {
    max-width: 1580px;
  }
}

.nav-link-wrapper a,
.dropdown-manual .dropdown-item {
  text-align: center;
}

.nav-link-wrapper a,
.dropdown-manual,
.dropdown-language-switch,
.dropdown-logout,
.dropdown-login {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.custom-dropdown-item:active {
    background-color: #ea7e30;
  }

.bg-body-tertiary {
  background-color: transparent !important;
}

@media (max-width: 780px) {
}
